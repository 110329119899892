import React from "react"
import { FaArrowRight } from "react-icons/fa"
import Link from "./link"

const LinkForward = ({ label = "Proceed", to = "/" }) => (
  <Link className="link-forward" to={to}>
    {label}
    <FaArrowRight className={"icon ml-1 small"} />
  </Link>
)

export default LinkForward
