import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Link from "./link"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import LinkForward from "./link-forward"

const PagePreview = props => {
  const image = props.image
  const slug = props.slug
  const title = props.title
  const description = props.description
  const reverse = props.reverse
  const links = props.links

  return (
    <section>
      <Row>
        <Col>
          <div
            className={`d-flex flex-column align-items-center flex-md-row${
              reverse ? "-reverse" : ""
            } align-items-md-start`}
          >
            <div className={`${reverse ? "ml-md-5" : "mr-md-5"}`}>
              <h2 className="text-center text-md-left">
                <Link to={slug}>{title}</Link>
              </h2>
              <p
                dangerouslySetInnerHTML={{ __html: description }}
                className="text-center text-md-left"
              ></p>
              {links && links.length ? (
                links.map(link => (
                  <p className="text-center text-md-left">
                    <LinkForward
                      key={link.href}
                      to={link.href}
                      label={link.title}
                    />
                  </p>
                ))
              ) : (
                <p className="text-center text-md-left">
                  <LinkForward to={slug} label={"Mehr"} />
                </p>
              )}
            </div>
            {image ? (
              <div
                style={{
                  paddingTop: "10px",
                  minWidth: "350px",
                  maxWidth: "350px",
                }}
              >
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  alt={title}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </section>
  )
}

PagePreview.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.any,
  slug: PropTypes.string,
  reverse: PropTypes.bool,
  links: PropTypes.array,
}

PagePreview.propDefaults = {
  title: "Page Title",
  description: "Some text describing the page...",
  image: undefined,
  slug: "/",
  reverse: false,
  links: undefined,
}

export default PagePreview
