import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "react-bootstrap/Container"
import { graphql } from "gatsby"
import { scrollToRef } from "../utils/dom-utils"
import { globalHistory } from "@reach/router"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "react-bootstrap/Button"
import { FaFileDownload } from "react-icons/fa"
import PagePreview from "../components/page-preview"
import Link from "../components/link"
import LinkBack from "../components/link-back"

const VereinPage = ({ location, data }) => {
  const mitgliedRef = useRef(null)

  const navigateToHash = hash => {
    if (!hash) return
    switch (hash) {
      case "#mitglied-werden":
        scrollToRef(mitgliedRef)
        break
      default:
        break
    }
  }

  setTimeout(() => navigateToHash(location.hash), 100)

  useEffect(() => {
    // whenever the route changes we change the tab depending on the hash of the url
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        const hash = globalHistory.location.hash
        navigateToHash(hash)
      }
    })
  })

  return (
    <Layout>
      <Seo title="Verein" />
      <section
        id="banner-verein"
        className="banner d-flex flex-column justify-content-end"
      >
        <Container
          className="text-center text-md-left"
          style={{ maxWidth: "900px" }}
        >
          <p>
            <LinkBack to="/" label={"Homepage"} />
          </p>
          <h1>Unser Verein</h1>
        </Container>
      </section>
      <Container
        className="text-center text-md-left"
        style={{ maxWidth: "900px" }}
      >
        <section>
          <p>
            Seit 1952 bieten wir inmitten des Volksdorfer Walds einen
            Tennisgenuss der Extraklasse. Auf unserer weitläufigen Anlage
            bestehend aus acht Außenplätzen und zwei Hallenplätzen kommen Jung
            und Alt auf ihre Kosten. Schauen Sie vorbei und machen Sie sich
            selbst ein Bild!
          </p>
          <div
            className={`d-flex mt-5 flex-column align-items-center flex-md-row justify-content-md-between`}
          >
            <GatsbyImage
              style={{
                float: "left",
                height: "300px",
                width: "350px",
                objectFit: "cover",
              }}
              image={data.anlageImg.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage
              style={{
                float: "left",
                height: "300px",
                width: "350px",
                objectFit: "cover",
              }}
              image={data.halleImg.childImageSharp.gatsbyImageData}
            />
            <GatsbyImage
              style={{
                float: "left",
                height: "300px",
                width: "350px",
                objectFit: "cover",
              }}
              image={data.ausklangImg.childImageSharp.gatsbyImageData}
            />
          </div>
        </section>
        <PagePreview
          title="Gastronomie"
          slug="/gastronomie"
          description="Die Gastronomie mit großer Terrasse und Blick auf die Plätze lädt
            zum Verweilen ein. Gönnen Sie sich ein erfrischendes Getränk nach dem hart erkämpften Sieg im Tiebreak."
        />
        <section>
          <h2>Aktuelle Hallenbelegung </h2>
          <p>
            Unsere Mitglieder und auch Gäste sind alle herzlich eingeladen,
            einen Platz in unserer schönen Tennishalle zu buchen. Einzelstunden
            könnt ihr per{" "}
            <a href="https://www.bookandplay.de/cgi-bin/WebObjects/BookAndPlay.woa/wa/directAccess?id=HTV-2029">
              Book and Play
            </a>{" "}
            bzw. per Link ("Platz buchen") rechts oben auf unserer Startseite
            buchen. Dort könnt ihr auch sehen, wann welche Plätze noch frei sind
            und was eine Einzelstunde kostet. In der Wintersaison könnt ihr auch
            eine Abostunde, also einen Platz zu einer festen Zeit über die
            gesamte Wintersaison, buchen. Eine Übersicht zu den Preisen und den
            aktuellen Belegungsplan findet ihr weiter unten. Grundsätzlich gilt
            bei den Abostunden die Regel, dass sich eine Abostunde von
            Wintersaison zu Wintersaison automatisch verlängert, also nicht
            immer wieder neu gebucht werden muss. Wenn ihr eine freie Stunde
            buchen möchtet, müsst ihr euch bitte im Sekretariat bei Frau Boye
            per Telefon oder besser sogar per Mail{" "}
            <a href="mailto:buero@wthc-volksdorf.de">buero@wthc-volksdorf.de</a>{" "}
            melden. Dort könnt ihr auch Bescheid sagen, wenn ihr euch für eine
            Abostunde interessiert zu einer bestimmten Zeit oder in einem
            Zeitfenster, wo laut aktuellem Belegungsplan keine Stunde frei ist;
            dann würde Frau Boye euch auf unsere Reservierungsliste setzen und
            ihr würdet benachrichtigt, sobald eine Stunde frei wird.
          </p>
          <p>
            Unsere Tennishalle ist mit einem gelenkschonenden Teppichboden
            ausgestattet, weshalb ihr zum Spielen Hallenschuhe mit einer glatten
            Sohle benötigt.
          </p>
          <div
            className={`d-flex mt-5 flex-column align-items-center flex-md-row justify-content-md-between`}
          >
            <GatsbyImage
              style={{
                float: "left",
                height: "900px",
                width: "850px",
                objectFit: "cover",
              }}
              image={data.hallenbelegungImg.childImageSharp.gatsbyImageData}
            />
          </div>
        </section>
        <section>
          <h2 ref={mitgliedRef}>Mitglied werden</h2>
          <p>
            Wenn Sie den Wunsch haben, bei uns Mitglied zu werden, richten Sie
            bitte entweder einen schriftlichen Antrag an uns oder kommen zu
            unseren Bürozeiten auf die Anlage oder rufen einfach einmal an.
          </p>
          <p>
            Wenn Sie vorher erst einmal “reinschnuppern” möchten, können Sie
            auch gerne zunächst mit einem unserer Trainer (siehe Trainerliste
            unter “Training”) bis zu drei Schnupperstunden vereinbaren und erst
            danach entscheiden, ob Sie Mitglied werden.
          </p>
          <table
            className="mb-4 mt-4 text-left"
            style={{ tableLayout: "fixed" }}
          >
            <colgroup>
              <col style={{ width: "400px" }} />
              <col style={{ width: "50px" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Tennis incl. Beach-Sport</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Aktive Erwachsene</td>
                <td className="text-right">340 €</td>
              </tr>
              <tr>
                <td>Aktive Jugendliche bis 25 Jahre</td>
                <td className="text-right">160 €</td>
              </tr>
              <tr>
                <td>Aktive Jugendliche bis 10 Jahre</td>
                <td className="text-right">80 €</td>
              </tr>

              <tr>
                <td className="pt-3">
                  <i>Familienpauschalen</i>
                </td>
              </tr>
              <tr>
                <td>Ehepaare (Partner)</td>
                <td className="text-right">620 €</td>
              </tr>
              <tr>
                <td>Zwei aktive Erwachsene + Kinder</td>
                <td className="text-right">780 €</td>
              </tr>
              <tr>
                <td>Ein aktiver Erwachsener + Kinder</td>
                <td className="text-right">500 €</td>
              </tr>
            </tbody>
          </table>
          <table className="mb-4 text-left" style={{ tableLayout: "fixed" }}>
            <colgroup>
              <col style={{ width: "400px" }} />
              <col style={{ width: "50px" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Passive Mitgliedschaft</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Erwachsene</td>
                <td className="text-right">60 €</td>
              </tr>
            </tbody>
          </table>
          <table className="mb-4 text-left" style={{ tableLayout: "fixed" }}>
            <colgroup>
              <col style={{ width: "400px" }} />
            </colgroup>
            <thead>
              <tr>
                <th>Beach-Sport</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Kann über das Online-Buchungssystem Book&Play reserviert
                  werden.{" "}
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Für <b>Neumitglieder</b> reduziert sich der Jahresbeitrag im{" "}
            <b>Eintrittsjahr</b> für Beitritte ab dem 1.3. um 25%, für Beitritte
            ab dem 1.6. um 50% und für Beitritte nach den Sommerferien um 75%.
          </p>
          <p>Die Beiträge werden per Lastschrift eingezogen.</p>
          <div className="d-flex flex-column flex-md-row my-4">
            <Button
              href="/wthc-aufnahmeantrag.pdf"
              target="_blank"
              variant="outline-primary"
              className="mb-3 mb-md-0 mr-md-3 d-flex align-items-center justify-content-center"
            >
              Aufnahmeantrag
              <FaFileDownload className="ml-1" />
            </Button>
            {/*
            <Button href="/wthc-aufnahmeantrag-erwachsene.pdf" target="_blank" variant="outline-primary" className="mb-3 mb-md-0 mr-md-3 d-flex align-items-center justify-content-center">
              Aufnahmeantrag Erwachsene<FaFileDownload className="ml-1"/>
            </Button>
            <Button href="/wthc-aufnahmeantrag-jugendliche.pdf" target="_blank" variant="outline-primary" className="mb-3 mb-md-0 mr-md-3 d-flex align-items-center justify-content-center">
              Aufnahmeantrag Jugendliche<FaFileDownload className="ml-1"/>
            </Button>
            */}
          </div>
          <p className="mt-5">
            Bei Fragen können Sie uns gerne{" "}
            <Link to="/kontakt">kontaktieren</Link>.
          </p>
          <p>
            <Link to="/vereinssatzung">Vereinssatzung</Link>
          </p>
          <p>
            <Link to="/vorstand">Vorstand</Link>
          </p>
        </section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    anlageImg: file(relativePath: { eq: "gastro03f.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    halleImg: file(relativePath: { eq: "halle.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    ausklangImg: file(relativePath: { eq: "anlageausklang.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    beachImg: file(relativePath: { eq: "beachvolleyball.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    gastronomieImg: file(relativePath: { eq: "clubhouse.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
    hallenbelegungImg: file(relativePath: { eq: "hallenbelegung.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED)
      }
    }
  }
`

export default VereinPage
